<template>
  <div class="alerts">
    <div v-for="(card, index) in alerts" :key="index">
      <v-alert
        max-width="400"
        class="mt-6"
        dismissible
        elevation="9"
        :type="card.color === 'red' ? 'error' : 'success'"
        :color="card.color ? card.color : 'green'"
      >{{ card.msg }}</v-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['alerts'])
  },
  watch: {
    alerts (val) {
      console.table(val)
    }
  }
}
</script>

<style  scoped>
.alerts {
  position: fixed;
  top: 5rem;
  right: 3rem;
  z-index: 300;
}
.v-alert__content {
  padding: 0 10px;
}
</style>
