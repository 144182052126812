<template>
  <base-item-group
    :item="item"
    text
    :prepend-icon="item.icon"
    sub-group
    append-icon="mdi-menu-down"
    class="xyz"
  />
</template>

<script>
import BaseItemGroup from './BaseItemGroup.vue'

export default {
  components: { BaseItemGroup },
  name: 'BaseItemSubGroup',

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: []
      })
    }
  }
}
</script>
