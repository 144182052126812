<template>
  <div class="text-center">
    <!-- <div
      v-if="showBack"
      class="tw-text-black tw-fixed tw-bottom-4 tw-right-4 tw-z-50 tw-cursor-pointer tw-bg-white tw-py-2 tw-px-3 tw-rounded-full"
      @click="back"
      style="z-index: 90;"
    >
      <v-icon color="black">mdi-chevron-left</v-icon>Voltar
    </div> -->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      showBack: false,
      interval: null
    }
  },
  computed: {
    ...mapState(['loading'])
  },
  methods: {
    ...mapMutations(['setLoading']),
    back () {
      this.showBack = false
      this.setLoading(false)
      this.$router.go(-1)
    }
  },
  watch: {
    loading (newValue, oldValue) {
      if (newValue) {
        this.interval = setTimeout(() => {
          this.showBack = true
        }, 6000)
      } else {
        clearInterval(this.interval)
      }
      if (!newValue && oldValue) {
        clearInterval(this.interval)
      }
    }
  }
}
</script>
