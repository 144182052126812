<template>
  <v-list-group
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    :active-class="`primary white--text`"
  >
    <!-- :group="group" -->
    <template v-slot:activator>
      <v-list-item-icon v-if="item.sicon" class="sicon">
        <v-icon v-text="item.sicon" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, b) in children">
      <base-item-subgroup
        v-if="child.children"
        :key="`sub-group-${b}`"
        :item="child"
        class="second-dd"
      />

      <base-item v-else :key="`item-${b}`" :item="child" text />
    </template>
  </v-list-group>
</template>

<script>
import kebabCase from 'lodash/kebabCase'

export default {
  name: 'BaseItemGroup',

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: '',
        group: '',
        title: '',
        children: []
      })
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    children () {
      return this.item.children.map((item) => ({
        ...item,
        to: !item.to ? '' : `${this.item.group}/${item.to}`
      }))
    }
  }

}
</script>

<style>
</style>
