<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb
    :title="page.title"
    :icon="page.icon"
    :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <dashboard></dashboard>
    </base-card>
  </v-container>
</template>

<script>
import Dashboard from '../../components/dashboard/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data: () => ({
    page: {
      title: 'Dashboard'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: true,
        to: '/'
      }
    ]
  })
}
</script>
