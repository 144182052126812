<template>
  <div class="organizations_list">
    <v-data-table
      :headers="headers"
      :items="organizations"
      :server-items-length="pagination.total"
      :options.sync="pagination"
      @update:page="updatePagination"
      class="elevation-1">
      <template v-slot:body v-if="!loading">
        <tbody>
          <tr v-for="organization in organizations" :key="organization.id" class="text-center">
            <td class="clickable text-start">
              <template v-if="organization.data.company.logo">
                <img width="60" :src='organization.data.company.logo' :alt='organization.data.company.name.legal' />
              </template>
              <template v-else>
                Sem Logo
              </template>
            </td>
            <td class="clickable">
              <template v-if="!Array.isArray(organization.data.company)">
                {{ capitalize(organization.data.company.name.legal) }}
              </template>
              <template v-else>
                Instituição sem nome
              </template>
            </td>
            <td class="clickable">{{ capitalize(organization.data.address.street) }}</td>
            <td class="clickable">{{ organization.data.address.number }}</td>
            <td class="clickable">{{ organization.data.status === 'ACTIVE' ? 'Ativo' : 'Inativo' }}</td>
            <td>
              <v-btn class="mr-3" color="primary" depressed small @click="edit(organization.metadata.id)">
                <v-icon small>mdi-pencil</v-icon><small>Editar</small>
              </v-btn>
              <!-- <v-btn color="secondary" depressed small>
                <v-icon small>mdi-delete</v-icon><small>Inativar</small>
              </v-btn> -->
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:body v-else>
        <tbody>
          <tr class="text-center">
            <td colspan="6">
              Carregando dados... Aguarde
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from '@/services/apiService'
import { capitalize } from '@/libs/helpers'
import router from '@/router'

export default {
  name: 'ListOrganizations',
  data: () => ({
    headers: [
      {
        text: 'Logo',
        align: 'start',
        sortable: false,
        value: 'logo'
      },
      {
        text: 'Nome',
        align: 'center',
        sortable: false,
        value: 'legal'
      },
      {
        text: 'Endereço',
        value: 'street',
        align: 'center',
        sortable: false
      },
      {
        text: 'Número',
        value: 'number',
        align: 'center',
        sortable: false
      },
      {
        text: 'Situação',
        value: 'status',
        align: 'center',
        sortable: false
      },
      {
        text: 'Ações',
        value: '',
        align: 'center',
        sortable: false
      }
    ],
    organizations: [],
    loading: false,
    pagination: {
      current_page: 1,
      total: 0,
      first_page_url: null,
      next_page_url: null,
      per_page: 15
    }
  }),
  methods: {
    capitalize,
    edit (organizationId) {
      router.push({
        path: `/organizations/edit/${organizationId}`,
        replace: true
      })
    },
    async updatePagination (pagination) {
      this.loading = true
      const response = await api.get(`/organizations?page=${pagination}`)
      this.organizations = response.data.items
      this.pagination = response.data.pagination
      if (response.status === 200) {
        this.loading = false
      }
    },
    async getOrganizations () {
      this.loading = true
      const response = await api.get('/organizations?page=1')
      this.organizations = response.data.items
      this.pagination = response.data.pagination
      if (response.status === 200) {
        this.loading = false
      }
    }
  },
  async mounted () {
    await this.getOrganizations()
  }
}
</script>

<style>
.clickable {
  cursor: pointer;
}

.organizations_list {
  padding: 25px;
}
</style>
