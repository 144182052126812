<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb
    :title="page.title"
    :icon="page.icon"
    :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <div class="text-end mr-10 mt-6">
        <v-btn color="primary" @click="create()">
          <v-icon dense dark>mdi-plus</v-icon> <strong>Organização</strong>
        </v-btn>
      </div>
      <list-organizations></list-organizations>
    </base-card>
  </v-container>
</template>

<script>
import ListOrganizations from '../../components/organizations/ListOrganizations.vue'
import router from '@/router'

export default {
  components: {
    ListOrganizations
  },
  data: () => ({
    page: {
      title: 'Organizações'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Organizações',
        disabled: true,
        to: '/organizations'
      }
    ]
  }),
  methods: {
    create () {
      router.push({
        path: '/organizations/create', replace: true
      })
    }
  }
}
</script>
