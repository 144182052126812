<template>
  <v-app>
    <v-row class="d-flex">
      <v-col cols="12" lg="7" xl="6" class="primary d-flex align-center justify-center">
        <v-container>
          <div class="pa-10">
            <v-row justify="center">
              <v-col cols="10" md="8" xl="5">
                <div class="d-flex align-center align-md-start justify-start flex-column">
                  <img src="@/assets/images/logo/logo-pruvo-white.png" class="logo-pruvo" />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
        <v-container>
          <div class="pa-7 pa-sm-12">
            <v-row>
              <v-col cols="12" lg="9" xl="6">
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Painel Administrativo</h2>
                <h6 class="subtitle-1">
                  Faça seu login.
                </h6>

                <div class="text-center mt-6 mb-10">
                  <v-btn color="primary" depressed class="button-google" @click="loginGoogle()">
                    <img src="@/assets/images/logo/g-logo.png" rounded class="icon-google" />
                    <span class="text-google font-weight-medium">Entrar com o Google</span>
                  </v-btn>
                </div>
                <v-row align="center" class="mb-6 px-3">
                  <v-divider />
                  <span class="mx-2" style="color: #00000099">ou</span>
                  <v-divider />
                </v-row>
                <v-form @keyup.native.enter="submit" ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="form.email"
                    :rules="[rules.email, rules.required]"
                    validate-on-blur
                    label="E-mail"
                    class="mt-4"
                    name="email"
                    dense
                    required
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="form.password"
                    :rules="[rules.password]"
                    validate-on-blur
                    label="Senha"
                    dense
                    name="password"
                    required
                    outlined
                    @click:append="() => (show1 = !show1)"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  ></v-text-field>
                  <v-btn
                    :disabled="!valid"
                    color="info"
                    block
                    class="mr-4"
                    submit
                    outlined
                    @click="submit"
                  >Entrar</v-btn>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
import baseAPI from '@/libs/baseApi.js'
export default {
  data: () => ({
    valid: true,
    show1: false,
    form: {
      email: '',
      password: ''
    },
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      email: v => {
        if (!/.+@.+\..+/.test(v)) return 'O e-mail deve ser válido.'
        if (v.indexOf(' ') >= 0) return 'Não são permitidos espaços.'
      },
      password: v => (v && !!v.trim()) || 'A senha é obrigatória.'
    },
    checkbox: false
  }),
  methods: {
    ...mapMutations(['setLoading']),
    async submit () {
      this.$refs.form.validate()
      if (this.$refs.form.validate(true)) {
        try {
          this.setLoading(true)
          const request = await this.$axios.post('/auth/login', this.form)
          const token = request.data.access_token
          this.tokenLogin(token)
        } catch (error) {
          this.setLoading(false)
          let msg = 'Erro na conexão, tente novamente'
          if (error.response.data.error === 'Unauthorized') {
            msg = 'Email ou senha incorretos'
          }
          this.$store.dispatch('alert', { color: 'red', msg })
        }
      } else {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Preencha todos os campos de login'
        })
      }
    },
    async tokenLogin (token) {
      this.setLoading(true)
      localStorage.setItem('jwtToken', token)
      this.$axios.defaults.headers.Authorization = 'bearer ' + token
      await this.$nextTick()
      this.$router.push({ path: '/' })
    },
    loginGoogle () {
      const newWindow = this.openWindow('', 'message')
      newWindow.location.href = baseAPI + '/auth/oauth/google'
    },
    onMessage (e) {
      if (!e.data.access_token) {
        return
      }
      this.tokenLogin(e.data.access_token)
    },
    openWindow (url, title, options = {}) {
      if (typeof url === 'object') {
        options = url
        url = ''
      }

      options = { url, title, width: 600, height: 720, ...options }

      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screen.left
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screen.top
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        window.screen.width
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        window.screen.height

      options.left = width / 2 - options.width / 2 + dualScreenLeft
      options.top = height / 2 - options.height / 2 + dualScreenTop

      const optionsStr = Object.keys(options)
        .reduce((acc, key) => {
          acc.push(`${key}=${options[key]}`)
          return acc
        }, [])
        .join(',')

      const newWindow = window.open(url, title, optionsStr)

      if (window.focus) {
        newWindow.focus()
      }

      return newWindow
    }
  },
  mounted () {
    window.addEventListener('message', this.onMessage, false)
  },

  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
  }
}
</script>
<style scoped>
.logo-pruvo {
  max-width: 200px;
}
.button-google {
  height: 50px !important;
  padding: 1px 1px !important;
  width: 100% !important;
}
.icon-google {
  position: absolute;
  left: 0;
  width: auto;
  height: 48px;
  margin-right: 24px;
  background-color: #fff;
  padding: 12px 12px !important;
  border-radius: 3px;
}
.text-google {
  font-size: 16px;
  margin-left: 22px;
}
</style>
