<template>
  <v-app-bar
    app
    flat
    clipped-left
    clipped-right
    :color="navbarColor"
    :dark="navbarColor !== 'white'"
  >
    <v-toolbar-title @click="goToHome()" class="align-center d-flex logo-width pointer" v-if="navbarColor !== 'white'">
      <span class="logo-icon">
        <img src="@/assets/images/logo/logo-pruvo-icon.png" class="mt-2" />
      </span>
      <span class="logo-text ml-2 d-none d-md-block">
        <img src="@/assets/images/logo/logo-pruvo-text.png" class="mt-2" />
      </span>
    </v-toolbar-title>
    <!---/Toggle sidebar part -->
    <div class="d-md-none">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div>

    <v-spacer />

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40" color="#fff">
            <img v-if="user.me && user.me.data.avatar" :src="user.me.data.avatar" />
            <v-icon v-else color="primary">mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          :href="item.href"
          :to="item.to"
          @click="action(item.action)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default () {
        return {
          me: {
            avatar: null,
            email: '',
            name: '',
            id: ''
          }
        }
      }
    }
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    timer: null,
    userprofile: [
      { title: 'Sair', action: 'logout' }
    ]
  }),
  mounted () {
    document.onkeydown = this.KeyPress

    const context = this
    this.$refs.touch.$el.addEventListener('touchstart', () => {
      this.touchstart()
    })
    this.$refs.touch.$el.addEventListener('touchmove', () => {
      this.touchend()
    })
    this.$refs.touch.$el.addEventListener('touchend', () => {
      this.touchend()
    })
  },

  computed: {
    ...mapState(['navbarColor', 'Sidebar_drawer'])
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: 'SET_SIDEBAR_DRAWER'
    }),
    goToHome () {
      if (this.$router.currentRoute.path !== '/organization') {
        this.$router.push('/')
      }
    },
    KeyPress (e) {
      var evtobj = e
      if (evtobj.keyCode === 90 && evtobj.ctrlKey) this.showSelectOrganization = true
    },
    touchstart () {
      this.timer = setTimeout(() => { this.showSelectOrganization = true }, 1000)
    },
    touchend () {
      if (this.timer) { clearTimeout(this.timer) }
    },
    action (key) {
      switch (key) {
        case 'logout':
          this.logout()
          break

        default:
          break
      }
    },
    logout () {
      this.$store.dispatch('setDataAuth', {})
      this.$axios.defaults.headers.Authorization = null
      this.$axios.defaults.headers.common.Authorization = null
      localStorage.clear()
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style>
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 0;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
.pointer:hover {
  cursor: pointer
}
</style>
