<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>
        <h4 class="font-weight-medium">
          {{ heading }}
          <template v-if="chips.show">
            <v-chip small class="ma-2" outlined :color="chips.color">{{ chips.text }}</v-chip>
          </template>
        </h4>
        <p class="text-subtitle-2 my-0 py-0 font-weight-light">{{ subtitle }}</p>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="button.show && !($vuetify.breakpoint.mobile && dialog)">
        <v-btn :to="button.url" v-if="manage" depressed color="primary">Gerenciar {{ button.text }}</v-btn>
        <v-btn
          @click="dialog ? $emit('addModal') : $router.push(button.url)"
          depressed
          color="primary"
          class="ml-2"
        >
          <v-icon left>{{ button.icon }}</v-icon>
          {{ button.text }}
        </v-btn>
      </template>
    </v-toolbar>
    <v-row v-if="$vuetify.breakpoint.mobile && dialog">
      <v-col cols="12" align="center">
        <template v-if="button.show">
          <v-btn @click="$emit('addModal')" depressed color="primary" class="mb-2">
            <v-icon left>{{ button.icon }}</v-icon>
            {{ button.text }}
          </v-btn>
        </template>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    manage: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: ''
    },
    button: {
      type: Object,
      default () {
        return {
          show: false,
          url: null,
          text: null,
          icon: null
        }
      }
    },
    chips: {
      type: Object,
      default () {
        return {
          show: false,
          color: null,
          text: null
        }
      }
    }
  },
  data: () => ({})

}
</script>
