<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb
    :title="page.title"
    :icon="page.icon"
    :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <form-user></form-user>
    </base-card>
  </v-container>
</template>

<script>
import FormUser from '@/components/users/FormUser.vue'
import router from '@/router'

export default {
  components: {
    FormUser
  },
  data: () => ({
    page: {
      title: 'Criar Usuário'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Usuários',
        disabled: false,
        to: '/users'
      }
    ]
  }),
  methods: {
    isEdit () {
      return this.$router.currentRoute.name === 'Editar Usuário'
    }
  },
  async mounted () {
    if (this.isEdit()) {
      this.page.title = 'Editar Usuário'
    }
  }
}
</script>
