import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: 'white',
    SidebarBg: '',
    navbarColor: 'primary',
    setHorizontalLayout: false,
    // layout
    loading: false,
    firstLoading: true,
    user: {
      level: 0,
      access_token: '',
      me: {}
    },
    // user
    alerts: [],
    selectedCourse: {}
  },
  mutations: {
    SET_SIDEBAR_DRAWER (state, payload) {
      state.Sidebar_drawer = payload
    },
    SET_CUSTOMIZER_DRAWER (state, payload) {
      state.Customizer_drawer = payload
    },
    SET_SIDEBAR_COLOR (state, payload) {
      state.SidebarColor = payload
    },
    SET_NAVBAR_COLOR (state, payload) {
      state.navbarColor = payload
    },
    SET_LAYOUT (state, payload) {
      state.setHorizontalLayout = payload
    },
    // layout

    SET_UserMe (state, payload) {
      // console.log(payload)
      state.user.me = payload
    },
    SET_UserToken (state, payload) {
      state.user.access_token = payload
    },
    SET_UserLevel (state, payload) {
      state.user.level = payload
    },
    // user

    removeAlert (state) {
      state.alerts.shift()
    },
    setAlert (state, value) {
      state.alerts.push(value)
    },
    // alerts

    setLoading (state, payload) {
      state.loading = payload
    },
    SET_FirstLoading (state, payload) {
      state.firstLoading = payload
    },
    // loading

    setSelectedCourse (state, payload) {
      state.selectedCourse = payload
    }
  },
  actions: {
    setDataAuth ({ commit }, payload) {
      commit('SET_UserToken', payload.token)
      commit('SET_UserMe', payload.meData)
    },
    alert ({ commit }, payload) {
      commit('setAlert', payload)
      setTimeout(() => { commit('removeAlert') }, 7000)
    },
    setFirstLoading ({ commit }, payload) {
      if (!payload) {
        commit('SET_FirstLoading', payload)
        commit('setLoading', payload)
      }
      if (this.state.firstLoading) {
        commit('setLoading', payload)
      }
    }
  },
  getters: {
    token (state) {
      return state.user.access_token
    }
  }
})
