<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pa-0">
      <v-card rounded="0" class="px-2 custom-shadow">
        <v-breadcrumbs :items="breadcrumbs" class="pa-3">
          <template v-slot:divider v-if="breadcrumbs">
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <slot></slot>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'BaseBreadcrumb',
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String
  },
  data: () => ({})
}
</script>
<style>
.page-breadcrumb {
  margin: -24px -25px 27px -25px;
}
</style>
