<template>
  <v-container fluid>
    <v-form ref="form" lazy-validation >
      <v-card class="mb-7">
        <v-col>
          <v-text-field
            v-model="form.name"
            :rules="[rules.required]"
            required
            label="Nome do usuário"
            filled
            background-color="transparent"
          ></v-text-field>
          <v-text-field
            v-model="form.email"
            :rules="[rules.email]"
            required
            label="E-mail do usuário"
            filled
            background-color="transparent"
          ></v-text-field>
          <v-text-field
            v-model="form.password"
            label="Senha"
            counter
            filled
            background-color="transparent"
            :disabled="passwordField.disabled"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.password]"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          >
            <template v-if="passwordField.disabled" v-slot:append-outer>
              <v-menu style="top: -12px" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="mr-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="editPassword()"
                    ><small>Alterar senha</small>
                  </v-btn>
                </template>
              </v-menu>
            </template>
          </v-text-field>
          <v-switch
            v-model="form.is_super_admin"
            label="Super admin"
          ></v-switch>
        </v-col>
      </v-card>
      <v-card outlined class="mb-7">
        <v-col class="text-end">
          <v-select
            v-model="currentValue.id"
            :items="items.organizations"
            :loading="items.isLoading"
            :disabled="items.isDisabled || isEditingOrganization"
            filled
            item-text="text"
            item-value="value"
            label="Organizações"
            background-color="transparent"
            ></v-select>
            <v-select
              v-model="currentValue.roles"
              :items="items.roles"
              :loading="items.isLoading"
              :disabled="items.isDisabled"
              filled
              chips
              multiple
              label="Funções"
              background-color="transparent"
            ></v-select>
            <v-row>
              <v-col class="text-end">
                <v-btn v-if="isEditingOrganization" color="primary" class="mr-4" depressed @click="addCurrentValueToSelectedValues()">Alterar funções</v-btn>
                <v-btn v-else color="primary" class="mr-4" depressed @click="addCurrentValueToSelectedValues()">Adicionar organização</v-btn>
                <v-btn color="secondary" class="mr-4" depressed @click="clearSelection()">Cancelar</v-btn>
              </v-col>
           </v-row>
        </v-col>
      </v-card>
      <v-card
        outlined
        elevation="0"
        class="mb-10"
        >
        <v-card-text>
          <div class="text-overline mb-0">
            Organizações do usário:
          </div>
          <v-chip-group
            active-class="primary accent-4 white--text"
            column>
            <v-chip
              close
              :key="value.organization.id"
              @click="selectOrganizationFromChip(value.organization.id, value.organization.roles)"
              @click:close="confirmRemove(value.organization.id)"
              v-for="value in selectedValues"
              >
                {{value.organization.name}}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-end mb-7">
          <v-btn v-if="isEdit()" color="primary" class="mr-4" depressed @click="update()">Atualizar</v-btn>
          <v-btn v-else color="primary" class="mr-4" depressed @click="save()">Criar</v-btn>
          <v-btn color="secondary" class="mr-4" depressed @click="goBack()">Cancelar</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="300"
      >
        <v-card>
          <v-card-title class="text-h5">
            Tem certeza?
          </v-card-title>
          <v-card-text>
            Deseja realmente remover essa organização?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="blue darken-1"
              text
              @click="dialogResponse = 'NO'"
            >
              Não
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="dialogResponse = 'YES'"
            >
              Sim
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/services/apiService'
import router from '@/router'
import { mapMutations } from 'vuex'

export default {
  name: 'FormUser',
  data: () => ({
    show1: false,
    form: {
      name: '',
      email: '',
      password: '',
      accept_terms: true,
      is_super_admin: false
    },
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      email: v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (v.indexOf(' ') >= 0) return 'Não são permitidos espaços.'
        return pattern.test(v) || 'O e-mail deve ser válido.'
      },
      password: v => (v && !!v.trim()) || 'A senha é obrigatória.'
    },
    passwordField: {
      disabled: false
    },
    currentValue: {
      id: '',
      roles: []
    },
    selectedValues: [],
    items: {
      isLoading: true,
      isDisabled: true,
      organizations: [],
      roles: []
    },
    isEditingOrganization: false,
    organizationsToRemove: [],
    // Dialog
    dialog: false,
    dialogResponse: '',
    removeOrganizationID: ''
  }),
  methods: {
    ...mapMutations(['setLoading']),
    async save () {
      if (this.$refs.form.validate()) {
        this.setLoading(true)
        await api.post('/users', this.form)
          .then((response) => {
            const userID = response.data.metadata.id
            if (this.form.is_super_admin === false) {
              this.insertUserInOrganization(userID)
            }
            this.setLoading(false)
            this.$store.dispatch('alert', { msg: 'Usuário cadastrado com sucesso' })
            this.goBack()
          }).catch((e) => {
            this.setLoading(false)
            this.showError(e.response.data.errors)
          })
      }
    },
    async update () {
      this.setLoading(true)
      const userID = this.$route.params.id
      await api.patch(`/users/${userID}`, this.form)
        .then(res => {
          this.setLoading(true)
          this.insertUserInOrganization(userID)
          if (this.organizationsToRemove.length > 0) {
            this.deleteUserInOrganization()
          }
          this.$store.dispatch('alert', { msg: 'Usuário atualizado com sucesso' })
          this.goBack()
        }).catch((e) => {
          this.setLoading(false)
          // console.log(e)
        })
    },
    async insertUserInOrganization (userId) {
      await this.selectedValues.map(obj => {
        const payload = this.createPayload(userId, obj)
        api.post(`/organizations/${obj.organization.id}/people/sync`, payload)
      })
    },
    async deleteUserInOrganization () {
      await this.organizationsToRemove.map(obj => {
        api.delete(`/organizations/${obj.id}/people/${obj.people_id}`).catch(err => {
          console.log(err)
        })
      })
    },
    getRolesFormated (list) {
      const roles = [
        { name: 'ADMIN', operation: 'REMOVE' },
        { name: 'TECHNICAL', operation: 'REMOVE' },
        { name: 'FINANCIAL', operation: 'REMOVE' },
        { name: 'ORG_PEDAGOGICAL_COORDINATOR', operation: 'REMOVE' }
      ]
      list.map(role => {
        switch (role) {
          case 'ADMIN': {
            roles[0].operation = 'ADD'
            break
          }
          case 'TECHNICAL': {
            roles[1].operation = 'ADD'
            break
          }
          case 'FINANCIAL': {
            roles[2].operation = 'ADD'
            break
          }
          case 'ORG_PEDAGOGICAL_COORDINATOR': {
            roles[3].operation = 'ADD'
            break
          }
          default: {
            break
          }
        }
      })
      return roles
    },
    createPayload (userId, obj) {
      const payload = {
        users: [{
          user: { id: userId },
          roles: this.getRolesFormated(obj.organization.roles)
        }]
      }
      return payload
    },
    showError (errors) {
      if (errors.email) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'E-mail já está sendo utilizado'
        })
      }
    },
    addCurrentValueToSelectedValues () {
      if (this.currentValueExists() && this.currentValue.roles.length > 0) {
        const id = this.currentValue.id
        this.removeOrganizationFromList(id)
      }
      if (this.currentValue.id !== '' && this.currentValue.roles.length > 0) {
        const name = this.getNameOfOrganization(this.currentValue.id)
        this.selectedValues.push({
          organization: { name: name, id: this.currentValue.id, roles: this.currentValue.roles }
        })
      }
      this.isEditingOrganization = false
      this.clearSelection()
    },
    currentValueExists () {
      let isExist = false
      this.selectedValues.forEach(obj => {
        if (obj.organization.id === this.currentValue.id) {
          isExist = true
        }
      })
      return isExist
    },
    getNameOfOrganization (id) {
      let name = ''
      this.items.organizations.map((obj) => {
        if (obj.value === id) {
          name = obj.text
        }
      })
      return name
    },
    goBack () {
      router.push({ path: '/users/', replace: true })
    },
    removeOrganization () {
      const id = this.removeOrganizationID
      if (this.isEdit()) {
        this.selectedValues.map(obj => {
          if (obj.organization.id === id && obj.organization.people_id !== undefined) {
            this.organizationsToRemove.push({ id: id, people_id: obj.organization.people_id })
          }
        })
      }
      this.removeOrganizationFromList(id)
    },
    removeOrganizationFromList (id) {
      const arr = this.selectedValues.filter(obj => obj.organization.id !== id)
      this.selectedValues = arr
    },
    confirmRemove (id) {
      this.removeOrganizationID = id
      this.dialog = true
    },
    clearSelection () {
      this.isEditingOrganization = false
      this.currentValue = {
        id: '',
        roles: []
      }
    },
    isEdit () {
      return this.$router.currentRoute.name === 'Editar Usuário'
    },
    editPassword () {
      this.passwordField.disabled = false
    },
    selectOrganizationFromChip (id, roles) {
      this.isEditingOrganization = true
      this.currentValue = {
        id: id,
        roles: roles
      }
    }
  },
  watch: {
    dialogResponse () {
      if (this.dialogResponse === 'YES') {
        this.removeOrganization()
      }
      this.dialog = false
      this.dialogResponse = ''
      this.removeOrganizationID = ''
    }
  },
  async mounted () {
    this.setLoading(true)
    await api.get('/organizations').then(response => {
      response.data.items.map(obj => {
        this.items.organizations.push(
          { text: obj.data.company.name.trade, value: obj.metadata.id }
        )
      })
    })
    if (this.isEdit()) {
      this.passwordField.disabled = true
      await api.get(`/users/${this.$route.params.id}`).then(response => {
        const user = response.data
        this.form.name = user.data.name
        this.form.email = user.data.email
        this.form.is_super_admin = user.data.is_super_admin
        this.form.accept_terms = user.data.accept_terms
        delete this.form.accept_terms
        delete this.form.password
        user.data.acl.map(obj => {
          const nameOrganization = this.getNameOfOrganization(obj.organization_id)
          if (obj.type === 'ORGANIZATION') {
            this.selectedValues.push({
              organization: { id: obj.organization_id, roles: obj.roles, name: nameOrganization, people_id: obj.id }
            })
          }
        })
      })
    }
    this.items.roles = [
      { text: 'Admin', value: 'ADMIN' },
      { text: 'Financeiro', value: 'FINANCIAL' },
      { text: 'Técnico', value: 'TECHNICAL' },
      { text: 'Coordenador', value: 'ORG_PEDAGOGICAL_COORDINATOR' }
    ]
    this.items.isLoading = false
    this.items.isDisabled = false
    this.setLoading(false)
  }
}
</script>
