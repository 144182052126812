<template>
  <v-container>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col class="d-flex justify-center align-center" col="12" md="2">
          <img width="60" :src='form.company.logo' :alt='form.company.name.legal'>
        </v-col>
        <v-col col="12" md="10">
          <v-file-input
            filled
            label="Logo da Organização"
            small-chips
            prepend-inner-icon="mdi-camera"
            @change="uploadImage($event)">
          </v-file-input>
        </v-col>
        <v-col col="12" md="4">
          <v-text-field
          v-model="form.company.name.legal"
          label="Nome Institucional"
          prepend-inner-icon="mdi-office-building-outline"
          filled
          :rules="[rules.required]"
          required
          >
          </v-text-field>
        </v-col>
        <v-col col="12" md="4">
            <v-text-field
            v-model="form.company.name.trade"
            label="Nome Fantasia"
            prepend-inner-icon="mdi-office-building-outline"
            filled
            :rules="[rules.required]"
            required
            >
            </v-text-field>
        </v-col>
        <v-col col="12" md="4">
          <v-select
            :items="segments"
            v-model="form.company.segment"
            filled
            prepend-inner-icon="mdi-office-building-outline"
            label="Segmento"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
        <v-col col="12" md="4">
          <v-select
            :items="['CNPJ', 'CPF']"
            v-model="form.company.document.type"
            filled
            prepend-inner-icon="mdi-account-details-outline"
            label="Tipo de Documento"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
        <v-col col="12" md="4">
          <v-text-field
            v-model="form.company.document.id"
            label="Número do Documento"
            prepend-inner-icon="mdi-numeric"
            filled
            :rules="[rules.required]"
            required
            >
          </v-text-field>
        </v-col>
        <v-col col="12" md="4">
          <v-text-field
            v-model="form.address.zip"
            filled
            label="CEP"
            prepend-inner-icon="mdi-map-marker-outline"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.address.street"
            filled
            label="Endereço"
            prepend-inner-icon="mdi-map-marker-outline"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.address.number"
            filled
            label="Número"
            prepend-inner-icon="mdi-numeric"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.address.additional"
            filled
            label="Complemento"
            prepend-inner-icon="mdi-map-marker-plus-outline"
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.address.neighborhood"
            filled
            label="Bairro"
            prepend-inner-icon="mdi-home-group"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.address.country"
            filled
            label="País"
            prepend-inner-icon="mdi-earth"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.address.state"
            filled
            label="Estado"
            prepend-inner-icon="mdi-sign-real-estate"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.address.city"
            filled
            label="Cidade"
            prepend-inner-icon="mdi-city-variant-outline"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="3">
          <v-text-field
            v-model="form.billing.email"
            filled
            label="E-mail de Cobrança"
            prepend-inner-icon="mdi-at"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="4">
          <v-select
            :items="selectStatus"
            filled
            label="Situação"
            v-model="form.status"
            item-text="text"
            item-value="value"
            prepend-inner-icon="mdi-alert"
            :rules="[rules.required]"
            required
          ></v-select>
        </v-col>
        <v-col md="8">
          <v-combobox
            v-model="form.domains"
            prepend-inner-icon="mdi-domain-plus"
            label="Domínios"
            clearable
            filled
            multiple
            small-chips
            :rules="[rules.array]"
            required
          ></v-combobox>
        </v-col>
        <v-col col="12" md="6">
          <v-text-field
            v-model="form.licenses"
            filled
            label="Licenças"
            prepend-inner-icon="mdi-numeric"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col col="12" md="6">
          <v-text-field
            v-model="form.licenses_tolerance"
            filled
            label="Tolerância de Licenças"
            prepend-inner-icon="mdi-numeric"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-end">
          <v-btn color="secondary" class="mr-4" depressed @click="goBack()"><small>Voltar</small></v-btn>
          <v-btn color="primary" :disabled="send" depressed @click="save()"><small>Salvar</small></v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import api from '@/services/apiService'
import router from '@/router'

export default {
  name: 'CreateOrganization',
  data: () => ({
    form: {
      company: {
        logo: null,
        document: {
          id: '',
          type: ''
        },
        name: {
          legal: '',
          trade: ''
        },
        segment: ''
      },
      billing: {
        email: ''
      },
      domains: [],
      address: {
        street: '',
        neighborhood: '',
        number: '',
        additional: '',
        city: '',
        state: '',
        country: '',
        zip: ''
      },
      gcred_oauth_user: {
        id: ''
      },
      status: false,
      licenses: 0,
      licenses_tolerance: 0
    },
    image: null,
    selectStatus: [],
    segments: [],
    send: false,
    rules: {
      required: v => !!v || 'Campo obrigatório',
      array: v => v.length > 0 || 'Insira pelo menos 1 (um) item'
    }
  }),
  methods: {
    async uploadImage (event) {
      const data = new FormData()
      data.append('file', event.target.files[0])

      const response = await api.post('/medias', data)
      this.image = response.data.data
    },
    async save () {
      if (!this.$refs.form.validate()) return
      this.send = true
      delete this.form.gcred_oauth_user
      delete this.form.company.logo
      // this.form.company.logo = this.image
      await api.post('/organizations', this.form)
      this.goBack()
      this.send = false
    },
    goBack () {
      router.push({ path: '/organizations/', replace: true })
    }
  },
  async mounted () {
    this.selectStatus = [
      { text: 'Ativo', value: 'ACTIVE' },
      { text: 'Inativo', value: 'ARCHIVED' }
    ]
    this.segments = [
      { text: 'Governo Estadual', value: 'STATE_GOVERNMENT' },
      { text: 'Governo Municipal', value: 'LOCAL_GOVERNMENT' },
      { text: 'Governo Federal', value: 'FEDERAL_GOVERNMENT' },
      { text: 'Provedor de Banco de Dados', value: 'DATABASE_PROVIDER' },
      { text: 'Escola Privada', value: 'PRIVATE_SCHOOL' }
    ]
  }
}
</script>
