<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" md="3">
        <v-card
          elevation="2"
          outlined
          tile
        >
          <v-card-text>
            <v-chip
              class="ma-2 font-weight-bold"
              color="primary"
              x-large
              label
            >
              0 <br/>
              Organizações
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card
          elevation="2"
          outlined
          tile
        >
          <v-card-text>
            <v-chip
              class="ma-2 font-weight-bold"
              color="primary"
              x-large
              label
            >
              0 <br/>
              Usuários
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card
          elevation="2"
          outlined
          tile
        >
          <v-card-text>
            <v-chip
              class="ma-2 font-weight-bold"
              color="primary"
              x-large
              label
            >
              0 <br/>
              Escolas
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card
          elevation="2"
          outlined
          tile
        >
          <v-card-text>
            <v-chip
              class="ma-2 font-weight-bold"
              color="primary"
              x-large
              label
            >
              0 <br/>
              Turmas
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
