<template>
  <v-app>
    <v-row class="d-flex">
      <v-col cols="12" class="primary d-flex align-center justify-center">
        <v-container>
          <div class="pa-10">
            <v-row justify="center">
              <v-col cols="10" md="8" xl="5">
                <div class="d-flex align-center align-md-center justify-start flex-column">
                  <img src="@/assets/images/logo/logo-pruvo-white.png" class="logo-pruvo" />
                  <div class="">
                    <h6
                      class=" subtitle-1 mt-4 white--text op-5 font-weight-regular text-center"
                    >
                      Sua conta não tem permissão para acessar nenhuma instituição. Entre em contato com sua organização para receber ajuda.
                    </h6>

                  </div>
                    <v-btn dark outlined class="mt-3" @click="logout">Sair</v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data: () => ({
  }),
  methods: {
    ...mapMutations(['setLoading']),
    logout () {
      this.$store.dispatch('setDataAuth', {})
      this.$store.dispatch('organization/clearOrganization')
      this.$axios.defaults.headers.Authorization = null
      this.$axios.defaults.headers.common.Authorization = null
      localStorage.clear()
      this.$router.push({ path: '/login' })
    }
  }
}
</script>
<style scoped>
.logo-pruvo {
  max-width: 200px;
}
.button-google {
  height: 50px !important;
  padding: 1px 1px !important;
  width: 100% !important;
}
.icon-google {
  position: absolute;
  left: 0;
  width: auto;
  height: 48px;
  margin-right: 24px;
  background-color: #fff;
  padding: 12px 12px !important;
  border-radius: 3px;
}
.text-google {
  font-size: 16px;
  margin-left: 22px;
}
</style>
