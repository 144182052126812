function capitalize (words) {
  const sentence = words.toLowerCase().split(' ')

  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].substr(1)
  }

  return sentence.join(' ')
}

export { capitalize }
