<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb
    :title="page.title"
    :icon="page.icon"
    :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <edit-organization></edit-organization>
    </base-card>
  </v-container>
</template>

<script>
import EditOrganization from '../../components/organizations/EditOrganization.vue'

export default {
  components: {
    EditOrganization
  },
  data: () => ({
    page: {
      title: 'Editar Organização'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Organizações',
        disabled: false,
        to: '/organizations'
      },
      {
        text: 'Editar Organização',
        disabled: true,
        to: '/organizations/edit'
      }
    ]
  })
}
</script>
