export default [
  {
    title: 'Dashboard',
    icon: 'mdi-home-outline',
    to: '/dashboard'
  },
  {
    title: 'Organizações',
    icon: 'mdi-home-city-outline',
    to: '/organizations'
  },
  {
    title: 'Usuários',
    icon: 'mdi-account',
    to: '/users'
  }
]
