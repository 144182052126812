<template>
    <div id="materialpro" class="full-sidebar">
        <router-view />
        <base-alerts />
        <base-loading />
    </div>
</template>

<script>
import '@/assets/tailwind.css'

export default {
  name: 'App'
}
</script>
