<template>
  <v-card-actions class="tw-overflow-x-scroll md:tw-overflow-hidden mx-1 py-0 tw-min-h-[2.5rem] tw-min-w-[10rem]">
    <div
      v-for="(item, index) in (coursesGroup.length<=3) ? coursesGroup : coursesGroup.slice(1,4)"
      :key="item.id"
      :class="{ 'tw--ml-3': index > 0 }"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            size="36"
            class="tw-border-2 tw-border-white"
            style="box-shadow: -1px 1px 4px 0px rgb(0 0 0 / 20%);"
          >
            <v-img
              :src="`https://gravatar.com/avatar/${md5(
                                  item.id||'avatar'
                                )}?s=80&d=identicon&r=x`"
              v-on="on"
              v-bind="attrs"
              :alt="item.name"
            />
          </v-avatar>
        </template>
        <span>
          {{ item.name }} {{ item.grade }} {{ item.room ? '-' : '' }}
          {{ item.room }}
        </span>
      </v-tooltip>
      <v-avatar
        v-if="index===2 && (coursesGroup.length>3)"
        size="36"
        class="tw--ml-3 tw-border-2 tw-border-white tw-bg-white"
        style="box-shadow: -1px 1px 4px 0px rgb(0 0 0 / 20%); font-size: 12px;
    font-weight: 600;"
      >
        <span>+</span>
        <span>{{ coursesGroup.length-3 }}</span>
      </v-avatar>

      <v-avatar
        v-if="index===2 && (totalCourses>3)"
        size="36"
        class="tw--ml-3 tw-border-2 tw-border-white tw-bg-white"
        style="box-shadow: -1px 1px 4px 0px rgb(0 0 0 / 20%); font-size: 12px;
    font-weight: 600;"
      >
        <span>+</span>
        <span>{{ totalCourses-3 }}</span>
      </v-avatar>
    </div>
  </v-card-actions>
</template>

<script>
import md5 from '@/libs/md5'

export default {
  props: {
    coursesGroup: {
      type: Array,
      default: () => {
        return []
      }
    },
    totalCourses: {
      type: Number
    }
  },
  methods: {
    md5
  }
}
</script>

<style lang="scss" scoped>
</style>
