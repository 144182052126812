<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb
    :title="page.title"
    :icon="page.icon"
    :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <div class="text-end mr-10 mt-6">
        <v-btn color="primary" @click="create()">
          <v-icon dense dark>mdi-plus</v-icon> <strong>Usuário</strong>
        </v-btn>
      </div>
      <div class="mr-10 mt-6 ml-10">
        <v-select
          :items="organizations"
          v-model="selectedOrganization"
          background-color="transparent"
          filled
          label="Seleciona uma organização"
        ></v-select>
      </div>
      <list-users :selectedOrganization="selectedOrganization"></list-users>
    </base-card>
  </v-container>
</template>

<script>
import ListUsers from '@/components/users/ListUsers.vue'
import router from '@/router'
import api from '@/services/apiService'

export default {
  components: {
    ListUsers
  },
  data: () => ({
    page: {
      title: 'Usuários'
    },
    organizations: [],
    selectedOrganization: '',
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Usuários',
        disabled: true,
        to: '/users'
      }
    ]
  }),
  methods: {
    create () {
      router.push({
        path: '/users/create',
        replace: true
      })
    },
    async getOrganizations () {
      await api.get('/organizations')
        .then(response => {
          response.data.items.map(obj => {
            const name = obj.data.company.name
            const id = obj.metadata.id
            if (name !== undefined) {
              this.organizations.push(
                { text: name.trade, value: id }
              )
            }
          })
        })
        .catch(e => console.log(e))
    }
  },
  async mounted () {
    await this.getOrganizations()
  }
}
</script>

<style>

</style>
