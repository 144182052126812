import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueGtag from 'vue-gtag'
import api from '@/services/apiService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('../views/pages/authentication/Login.vue')
  },
  {
    path: '/register',
    component: () => import('../views/pages/authentication/Register.vue')
  },
  {
    path: '/password/forgot/',
    component: () => import('../views/pages/authentication/password/Forgot.vue')
  },
  {
    path: '/permission',
    component: () => import('../views/pages/authentication/Permission.vue')
  },
  {
    path: '/',
    component: () => import('../views/layouts/MainLayout.vue'),
    async beforeEnter (to, from, next) {
      const token = localStorage.getItem('jwtToken')
      try {
        const request = await api.post('/auth/me')
        const meData = request.data
        if (!meData) return
        await store.dispatch('setDataAuth', { token, meData })
        if (meData.data.is_super_admin) {
          next()
        } else {
          next('/permission')
        }
      } catch (err) {
        const msg = 'Erro interno: Verifique suas permissões e/ou se seu usuário está em uma organização.'
        const needAuthentication = 'Área restrita: realize login para acessar.'
        if (err.request.status === 401) store.dispatch('alert', { color: 'red', msg: needAuthentication })
        if (token) store.dispatch('alert', { color: 'red', msg })
        console.log(err)
        api.defaults.headers.Authorization = null
        api.defaults.headers.common.Authorization = null
        localStorage.clear()
        next('/login')
      } finally {
        store.commit('setLoading', false)
      }
    },
    children: [
      {
        name: 'Home',
        path: '/',
        redirect: '/dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue')
      },
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue')
      },
      {
        name: 'Organizações',
        path: '/organizations',
        component: () => import('@/views/organizations/ListOrganizations.vue')
      },
      {
        name: 'Criar Organização',
        path: '/organizations/create',
        component: () => import('@/views/organizations/CreateOrganization.vue')
      },
      {
        name: 'Editar Organização',
        path: '/organizations/edit/:id',
        component: () => import('@/views/organizations/EditOrganization.vue')
      },
      {
        name: 'Usuários',
        path: '/users',
        component: () => import('@/views/users/ListUsers.vue')
      },
      {
        name: 'Editar Usuário',
        path: '/users/edit/:id',
        component: () => import('@/views/users/FormUser.vue')
      },
      {
        name: 'Criar Usuário',
        path: '/users/create',
        component: () => import('@/views/users/FormUser.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Sentry.init({
  Vue,
  dsn: 'https://2f4f9b62288c4cdd9aa57d15b5575cc8@o1138815.ingest.sentry.io/6202055',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'admin.pruvo.app', /^\//]
    })
  ],
  tracesSampleRate: 0
})

Vue.use(VueGtag, {
  config: { id: 'G-9670CK7S0C' }
}, router)

router.beforeEach((to, from, next) => {
  store.dispatch('setFirstLoading', true)
  next()
})

router.afterEach((to, from, next) => {
  setTimeout(() => store.dispatch('setFirstLoading', false), 500)
})

export default router
