import Vue from 'vue'
import Vuetify from 'vuetify'
import pt from 'vuetify/es5/locale/pt'
import 'vuetify/dist/vuetify.min.css'
import '@/assets/sass/vuetify/overrides.scss'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const theme = {
  primary: '#1e88e5',
  info: '#1e88e5',
  success: '#21c1d6',
  accent: '#fc4b6c',
  default: '#563dea'
}

export default new Vuetify({
  theme: {
    themes: {
      light: theme,
      dark: theme
    },
    dark: false
  },
  rtl: false,
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: { pt },
    current: 'pt'
  }
})
