<template>
  <div class="users_list">
    <v-data-table :headers="headers" :items="users" :server-items-length="pagination.total"
      :options.sync="pagination" @update:page="updatePagination" class="elevation-1">
      <template v-slot:body v-if="!loading">
        <tbody>
          <tr v-for="user in users" :key="user.name" class="text-center">
            <td class="clickable text-start">
              <template  v-if="user.data.avatar">
                <img width="60" :src='user.data.avatar' :alt='user.data.name' />
              </template>
              <template v-else>
                Sem Avatar
              </template>
            </td>
            <td class="clickable">{{ capitalize(user.data.name) }}</td>
            <td class="clickable">{{ user.data.email }}</td>
            <td class="clickable">{{ user.data.is_super_admin ? 'Sim' : 'Não' }}</td>
            <td>
              <v-btn class="mr-3" color="primary" depressed small @click="edit(user)">
                <v-icon small>mdi-pencil</v-icon><small>Editar</small>
              </v-btn>
              <!-- <v-btn color="secondary" depressed small>
                <v-icon small>mdi-delete</v-icon><small>Inativar</small>
              </v-btn> -->
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:body v-else>
        <tbody>
          <tr class="text-center">
            <td colspan="5">
              Carregando dados... Aguarde
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import api from '@/services/apiService'
import { capitalize } from '@/libs/helpers'
import router from '@/router'

export default {
  name: 'ListUsers',
  props: {
    selectedOrganization: String
  },
  data: () => ({
    headers: [
      {
        text: 'Avatar',
        align: 'start',
        sortable: false,
        value: 'avatar'
      },
      {
        text: 'Nome',
        align: 'center',
        sortable: false,
        value: 'name'
      },
      {
        text: 'E-mail',
        value: 'email',
        align: 'center',
        sortable: false
      },
      {
        text: 'Super Admin',
        value: 'is_super_admin',
        align: 'center',
        sortable: false
      },
      {
        text: 'Ações',
        value: '',
        align: 'center',
        sortable: false
      }
    ],
    users: [],
    loading: false,
    pagination: {
      current_page: 1,
      total: 0,
      first_page_url: null,
      next_page_url: null,
      per_page: 15
    }
  }),
  watch: {
    selectedOrganization () {
      this.getUsers()
    }
  },
  methods: {
    capitalize,
    async updatePagination (pagination) {
      this.loading = true
      const response = await api.get(`/organizations/${this.selectedOrganization}/users?page=${pagination}`)
      this.users = response.data.items
      this.pagination = response.data.pagination
      if (response.status === 200) {
        this.loading = false
      }
    },
    edit (props) {
      router.push({ path: `/users/edit/${props.metadata.id}`, replace: true })
    },
    async getUsers () {
      this.clearPagination()
      this.loading = true
      const response = await api.get(`/organizations/${this.selectedOrganization}/users?page=1`)
      this.users = response.data.items
      this.pagination = response.data.pagination
      if (response.status === 200) {
        this.loading = false
      }
    },
    clearPagination () {
      this.pagination = {
        current_page: 1,
        total: 0,
        first_page_url: null,
        next_page_url: null,
        per_page: 15
      }
    }
  },
  async mounted () {
    // await this.getUsers()
  }
}
</script>
<style>
.users_list {
  padding: 25px;
}
</style>
