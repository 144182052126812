import Vue from 'vue'
import App from './views/pages/AppPruvo.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
// icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// mask
import { VueMaskDirective, VueMaskFilter } from 'v-mask'

Vue.directive('mask', VueMaskDirective)
Vue.filter('VMask', VueMaskFilter)

library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// icons
Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: 'AIzaSyDbQFon5DpQ68jJM--yUhVyv0Zwqu3_jxo',
  authDomain: 'pruvo-dev.firebaseapp.com',
  projectId: 'pruvo-dev',
  storageBucket: 'pruvo-dev.appspot.com',
  messagingSenderId: '500118421665',
  appId: '1:500118421665:web:e56db0b6e7ba5b2db0f0e0',
  measurementId: 'G-NRBTNQYKTT'
}

const requireComponent = require.context(
  './components',
  true, // deep search
  /[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
  )

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})
// components

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
